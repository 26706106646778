export const uvdTheme = {};

export const uvdPalette = {
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  ptr: {
    primary: {
      main: "#167DBC",
    },
  },
  blue: {
    brand: "#2677A7",
    primary: "#22A2D9",
    dark: "#118ABD",
  },
  green: {
    "400": "#1A933C",
    "600": "#1A9B37",
    "700p": "#07892B",
    "800": "#007821",
    "900": "#00590C",
  },
  greyscale: {
    "900": "#242525",
    "800": "#444444",
    "500": "#666666",
    "400": "#AAAAAA",
    "300": "#CCCCCC",
    "200": "#DDDDDD",
    "150": "#EEEEEE",
    "100": "#FAFAFA",
    "0": "#FFFFFF",
  },
  orange: {
    "800p": "#D43900",
    "900": "#BC2A00",
  },
  red: {
    "800p": "#B5221B",
    "900": "#A81B14",
  },
  yellow: {
    light: "#FFC400",
    dark: "#E9B404",
    warning: "#FDB500",
  },
  transparent: {
    "80": "rgba(255, 255, 255, 0.8)",
    "30": "rgba(255, 255, 255, 0.3)",
    "20": "rgba(255, 255, 255, 0.2)",
    "10": "rgba(255, 255, 255, 0.1)",
    dark20: "rgba(36, 37, 37, 0.2)",
  },
  disabled: {
    default: "rgba(0, 0, 0, 0.2)",
  },
};
