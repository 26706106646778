import { useSubscription } from "@apollo/client";
import { Box, Grid, Pagination, useTheme } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { CloudTextField } from "components/atoms/CloudTextField";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { RobotListCard } from "components/modules/RobotListCard";
import { AssignRobotCell } from "components/modules/tableCells/AssignRobotCell";
import { BatteryCell } from "components/modules/tableCells/BatteryCell";
import { SerialNumberCell } from "components/modules/tableCells/SerialNumberCell";
import { UVDRobotStatusCell } from "components/modules/tableCells/UVDRobotStatusCell";
import { ForbiddenPage } from "components/pages/ForbiddenPage";
import { graphql } from "gql";
import { IRobot, Privilege, ResultsPerPage, UvdRobot } from "gql/graphql";
import _, { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getIsForbidden } from "utils/privileges/privilegeUtils";
import { usePrivilege } from "utils/privileges/usePrivileges";
import {
  usePrefetchWithSnack,
  useQueryWithSnack,
} from "utils/useQueryWithSnack";
import { AddRobot } from "./dialogs/AddRobot";

export const UNASSIGNED_ROBOTS_QUERY = graphql(`
  query UnassignedRobots($search: String, $pagination: Pagination) {
    robots(search: $search, isActive: false, pagination: $pagination) {
      robots {
        id
        serialNumber
        ... on UvdRobot {
          status {
            batteryMergerPercentage
            lastTimeActive
            job {
              state
              type
            }
          }
          version {
            seto
          }
        }
      }
    }
  }
`);

export const UNASSIGNED_ROBOTS_COUNT_QUERY = graphql(`
  query UnassignedRobotsCount {
    robotCount(isActive: false)
  }
`);

export const ON_ROBOT_CHANGED = graphql(`
  subscription OnRobotChanged {
    robotChanged {
      id
      serialNumber
      robotType
      ... on UvdRobot {
        status {
          batteryMergerPercentage
          lastTimeActive
          job {
            state
            type
          }
        }
        version {
          seto
        }
      }
    }
  }
`);

export const UnassignedRobots = ({
  showGrid = false,
}: {
  showGrid?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const canAddRobots = usePrivilege(Privilege.RobotCreate).hasPrivilege;

  const [addRobotDialogOpen, setAddRobotDialogOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);

  const pageSize = 25;
  const pagesToPrefetch = 5;

  const { data: countData } = useQueryWithSnack(UNASSIGNED_ROBOTS_COUNT_QUERY);
  const prefetch = usePrefetchWithSnack(UNASSIGNED_ROBOTS_QUERY);

  const { data, loading, error, refetch } = useQueryWithSnack(
    UNASSIGNED_ROBOTS_QUERY,
    {
      variables: {
        search,
        pagination: {
          resultsPerPage: ResultsPerPage.ResultsPerPage25,
          page: page,
        },
      },
    },
  );

  const robots = data?.robots.robots as IRobot[] | undefined;

  useEffect(() => {
    const debouncedPrefetch = debounce(() => {
      // Prefetch pages close to current
      for (let i = Math.max(page - 5, 0); i <= page + pagesToPrefetch; i++) {
        prefetch({
          search,
          pagination: {
            resultsPerPage: ResultsPerPage.ResultsPerPage25,
            page: i,
          },
        });
      }

      // Prefetch last page
      prefetch({
        search,
        pagination: {
          resultsPerPage: ResultsPerPage.ResultsPerPage25,
          page: Math.max(
            Math.ceil((countData?.robotCount ?? 0) / pageSize) - 1,
            0,
          ),
        },
      });
    }, 200);

    debouncedPrefetch();

    return () => {
      debouncedPrefetch.cancel();
    };
  }, [page, prefetch, countData?.robotCount, search]);

  const sub = useSubscription(ON_ROBOT_CHANGED);
  useEffect(() => {
    if (robots && sub.data && sub.data.robotChanged) {
      const subRobot = sub.data.robotChanged as UvdRobot;
      const foundRobot = robots.find((o) => o.id == subRobot.id);
      if (foundRobot) {
        _.merge(foundRobot, subRobot);
      }
    }
  }, [robots, sub.data]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "serialNumber",
        maxWidth: 250,
        flex: 1,
        headerName: "Serial number",
        renderCell: SerialNumberCell,
      },
      {
        field: "battery",
        maxWidth: 250,
        flex: 1,
        headerName: "Battery",
        valueGetter: (params) => params.row?.status?.batteryMergerPercentage,
        renderCell: (params) => {
          if (!params.row?.status) return null;

          return (
            <BatteryCell
              robot={params.row}
              hideBattery={false}
              batteryMergerPercentage={
                params.row?.status?.batteryMergerPercentage
              }
            />
          );
        },
      },
      {
        field: "status",
        maxWidth: 250,
        flex: 1,
        headerName: "Status",
        sortable: false,
        renderCell: (params) => <UVDRobotStatusCell robot={params.row} />,
      },
      {
        field: "sw-version",
        maxWidth: 100,
        flex: 1,
        headerName: "SW version",
        align: "right",
        headerAlign: "right",
        valueGetter(params) {
          return params.row.version?.seto || "";
        },
      },
      {
        field: "assignAction",
        align: "right",
        maxWidth: 150,
        flex: 1,
        headerName: "",
        sortable: false,
        renderCell: (params) => (
          <AssignRobotCell {...params} refetch={refetch} />
        ),
      },
    ];
  }, []);

  const forbidden = getIsForbidden(error);
  if (forbidden) return <ForbiddenPage />;

  const pageCount = Math.ceil((countData?.robotCount ?? 0) / pageSize);

  return (
    <>
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <WidgetHeader
            label={
              <FormattedMessage
                id="unassigned-robots"
                defaultMessage="Unassigned robots"
              />
            }
            buttonLabel={canAddRobots ? "Add Robot" : undefined}
            onButtonClick={() => setAddRobotDialogOpen(true)}
          />
          <CloudTextField
            sx={{ mt: 2, width: "50%" }}
            placeholder="Type serial number"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <>
                  <IoSearch size={20} color={theme.palette.grey[500]} />
                </>
              ),
            }}
          />
        </Box>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
            Loading...
          </Box>
        ) : showGrid ? (
          <>
            <Grid container spacing={6} sx={{ mt: 2, mb: 2 }}>
              {(robots ?? []).map((robot) => (
                <Grid item key={robot.id}>
                  <RobotListCard
                    robot={robot}
                    onClick={() => navigate(robot.id)}
                  />
                </Grid>
              ))}
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <Pagination
                count={pageCount}
                page={page + 1}
                onChange={(_, newPage) => setPage(newPage - 1)}
                color="primary"
              />
            </Box>
          </>
        ) : (
          <CloudDataGrid
            rows={robots ?? []}
            columns={columns}
            loading={loading}
            onRowClick={(params: GridRowParams) => navigate(params.row.id)}
            pageSize={pageSize}
            page={page}
            onPageChanged={(pageNumber) => {
              setPage(pageNumber);
            }}
            rowCount={countData?.robotCount ?? 0}
            paginationMode="server"
            initialState={{
              sorting: {
                sortModel: [{ field: "serialNumber", sort: "asc" }],
              },
            }}
          />
        )}
      </WidgetPaper>

      <AddRobot
        open={addRobotDialogOpen}
        onClose={() => setAddRobotDialogOpen(false)}
        refetch={refetch}
      />
    </>
  );
};
