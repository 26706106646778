import {
  DisinfectionPositionStatus,
  ExposureImage,
  Map,
  mapElement,
} from "@blue-ocean-robotics/bor_ui";
import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import styled from "styled-components";

type DisinfectionPositionPointStatus = {
  name: string;
  status: string;
};
interface DisinfectionPositionsMapProps {
  room: any;
  map: any;
  mapImg: string;
  heatMapImg: string;
  positionsStatus: Array<DisinfectionPositionPointStatus>;
}

export interface Pose2D {
  x: number;
  y: number;
}

export interface DisinfectionPositionsData {
  uuid: string;
  position: Pose2D;
  text: string;
  status: DisinfectionPositionStatus;
  name: string;
}

export function DisinfectionPositionStatusMapper(
  status: string | undefined,
): DisinfectionPositionStatus {
  switch (status) {
    case "disinfected":
      return DisinfectionPositionStatus.Success;
    case "remaining":
      return DisinfectionPositionStatus.Remaining;
    case "failed":
      return DisinfectionPositionStatus.Failed;
    case "skipped":
      return DisinfectionPositionStatus.Remaining; //LSBRI - TODO: Skipped status not fully supported yet, requires work elsewhere
  }
  return DisinfectionPositionStatus.None; //Point will be rendered black on map in this case
}

const MapStyled = styled(Map)`
  width: 100%;
  height: 100%;
`;
const ExposureMapImage = styled(ExposureImage)`
  ${mapElement}
`;
const ScanImage = styled.img`
  ${mapElement}
`;
const StyledLegend = styled(ExposureImage.Legend)`
  transform: scale(0.5);
  transform-origin: left bottom;
  left: 0.75rem;
  bottom: 0.75rem;
`;
// const ZonesContainer = styled(Map.Image)`
//   pointer-events: none;
// `;

function DisinfectionPositionsMapConnected({
  map,
  room,
  mapImg,
  heatMapImg,
  positionsStatus,
}: DisinfectionPositionsMapProps) {
  if (!map || !room || !mapImg || !heatMapImg) {
    console.debug("DisinfectionPositionsMap: map or room is undefined");
    console.debug("map: ", !!map);
    console.debug("room: ", !!room);
    console.debug("mapImg: ", !!mapImg);
    console.debug("heatMapImg: ", !!heatMapImg);
    return <div></div>; //LSBRI - TODO: Add the "Map not available" placeholder here
  } else if (map && room && mapImg && heatMapImg) {
    console.debug("DisinfectionPositionsMap: map and room are ok");
  }

  // Load image and get dimensions
  // map.size.height & map.size.width are from DB and frequently not correct
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageDimensions({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
    img.src = mapImg;
  }, [mapImg]);

  const mapImage = {
    src: mapImg,
    height: imageDimensions.height,
    width: imageDimensions.width,
    resolution: map.resolution,
    originX: map.origin.x,
    originY: map.origin.y,
  };

  // LSBRI - TODO: Render zones on map
  // const zones = map.zones.map((item: any) => ({
  //   ...item,
  //   points: item.mapItems
  //     .map((point: any) => ({
  //       x: point.position.x,
  //       y: point.position.y,
  //       yaw: point.position.yaw || 0,
  //     }))
  //     .filter((point: Pose2D) => {
  //       if (point === undefined) {
  //         console.warn("Undefined map point: ", point);
  //         return false;
  //       }
  //       return !isNaN(point.x) && !isNaN(point.y);
  //     }),
  // }));

  const disinfectionPositionsSrc = room.mapItems.filter(
    (item: any) => item.category === "Disinfection position",
  );
  const positionIcons = disinfectionPositionsSrc.map((item: any) => ({
    uuid: item.id,
    position: {
      x: item.position.x,
      y: item.position.y,
      yaw: item.position.yaw || 0,
    },
    text: item.order.toString(),
    status: DisinfectionPositionStatusMapper(
      positionsStatus.find((position: any) => position.name === item.name)
        ?.status,
    ),
    name: item.name,
  }));

  const startPositionSrc = room.mapItems.find(
    (item: any) => item.category === "Start position",
  );
  const startPosition = startPositionSrc
    ? {
        uuid: startPositionSrc.id,
        position: {
          x: startPositionSrc.position.x,
          y: startPositionSrc.position.y,
          yaw: startPositionSrc.position.yaw || 0,
        },
        text: "0",
        status: DisinfectionPositionStatus.Remaining,
        name: startPositionSrc.name,
      }
    : undefined;

  // LSBRI - TODO: Render charging station on map
  // const chargingStationSrc = map.mapItems.find(
  //   (item: any) => item.category === "Docking station",
  // );

  // const chargingStation = chargingStationSrc
  //   ? {
  //       x: chargingStationSrc.position.x,
  //       y: chargingStationSrc.position.y,
  //       yaw: chargingStationSrc.position.yaw || 0,
  //     }
  //   : undefined;

  const mappedPositions = startPosition
    ? [...positionIcons, startPosition]
    : positionIcons;

  const [mapImageTrackerReady, setMapImageTrackerReady] = useState(false);
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false);

  // First render effect
  useEffect(() => {
    setHasRenderedOnce(true);

    return () => {
      (window as any).mapImageTracker = undefined;
    };
  }, []);

  const createImage = async () => {
    const canvas = await html2canvas(
      document.getElementById("disinfectionMapContainer") ??
        document.createElement("div"),
    );
    (window as any).mapImageTracker = canvas.toDataURL("image/png");
    setMapImageTrackerReady(true);
  };

  // Second render effect
  useEffect(() => {
    if (hasRenderedOnce && !(window as any).mapImageTracker) {
      const timer = setTimeout(createImage, 1000);
      return () => clearTimeout(timer);
    }
  }, [hasRenderedOnce]);

  const eventHandlers =
    Map.eventHandlers.centerOnDisinfectionPositions(mappedPositions);

  return (
    <div
      id="disinfectionMapContainer"
      style={{ width: "100%", height: "100%", backgroundColor: "#666666" }}
    >
      <MapStyled
        background={mapImage}
        eventHandlers={
          mappedPositions.length && mapImageTrackerReady
            ? eventHandlers
            : undefined
        }
      >
        <Map.Image>
          <ScanImage src={mapImage.src} />
          <ExposureMapImage src={heatMapImg} />
        </Map.Image>
        <Map.World>
          {mappedPositions.length ? (
            <Map.DisinfectionPositions positions={mappedPositions} />
          ) : null}
        </Map.World>
        <Map.Root>
          <StyledLegend title="UV Irradiation exposure" />
        </Map.Root>
      </MapStyled>
    </div>
  );
}

export default DisinfectionPositionsMapConnected;
