import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { WidgetWrapper } from "components/atoms/WidgetWrapper";
import { graphql } from "gql";
import { Disinfection } from "gql/graphql";
import { ThemeProvider } from "styled-components";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { getMissingMapMessage } from "utils/UVD/UVDUtils";
import { uvdPalette } from "../../../theme/uvdTheme";
import DisinfectionPositionsMap from "./DisinfectionPositionMap";

const QUERY = graphql(`
  query ExposureMap(
    $robotId: String!
    $heatMapFileName: String!
    $navigationMapFileName: String!
  ) {
    requestHeatMap: requestFile(
      robotId: $robotId
      mapType: HeatMap
      fileName: $heatMapFileName
    )
    requestNavigationMap: requestFile(
      robotId: $robotId
      mapType: NavigationMap
      fileName: $navigationMapFileName
    )
  }
`);

const MapStateWrapper = styled(WidgetWrapper)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.grey[500],
  textAlign: "center",
  lineHeight: "20px",
  flexGrow: 1,
}));
const MapStateText = styled(Typography)(() => ({
  maxWidth: 350,
}));

export const ExposureMap = ({
  selectedReport,
  mapStateWrapperStyle,
}: {
  selectedReport: Partial<Disinfection> | undefined;
  exportImage?: (callback: () => ImageData) => void;
  mapStateWrapperStyle?: any;
}) => {
  const navMapFileName = selectedReport?.navigationMap?.navigationMapFile?.name;

  const { data, error } = useQueryWithSnack(
    QUERY,
    {
      variables: {
        robotId:
          selectedReport?.robot?.robot?.id ?? selectedReport?.robotId ?? "",
        heatMapFileName: selectedReport?.heatMap?.name ?? "",
        navigationMapFileName: navMapFileName ?? "",
      },
      skip: !selectedReport?.heatMap?.name || !navMapFileName,
    },
    true,
  );

  const missingMapMessage = getMissingMapMessage(
    navMapFileName,
    selectedReport?.heatMap,
    selectedReport?.room,
    error,
  );

  if (missingMapMessage) {
    return (
      <MapStateWrapper style={mapStateWrapperStyle}>
        <MapStateText>{missingMapMessage}</MapStateText>
      </MapStateWrapper>
    );
  }

  return (
    <ThemeProvider
      theme={{
        palette: uvdPalette,
        spacing: (...args: number[]) => {
          if (args.length === 0) return "0";
          return args.map((factor) => `${factor * 8}px`).join(" ");
        },
        typography: {
          Button1: `
          font-family: Roboto;
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          line-height: 1.3;
        `,
        },
        textColor: {
          inherit: "inherit",
          primary: "#242525",
          secondary: "#22A2D9",
          initial: "initial",
          error: "#f44336",
          textPrimary: "#FFF",
          textSecondary: "#000",
        },
      }}
    >
      <DisinfectionPositionsMap
        room={
          selectedReport?.navigationMap?.zones
            ? selectedReport?.navigationMap?.zones.find((zone) => {
                return zone.name === selectedReport.room;
              })
            : undefined
        }
        map={selectedReport?.navigationMap}
        mapImg={data?.requestNavigationMap ?? ""}
        heatMapImg={data?.requestHeatMap ?? ""}
        positionsStatus={selectedReport?.positions ?? []}
      />
    </ThemeProvider>
  );
};
