import { gql } from "@apollo/client";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { HeaderGrid } from "components/atoms/HeaderGrid";
import { UserCardContent } from "components/modules/cards/UserCardContent";
import { MultipleOrganizationCell } from "components/modules/tableCells/MultipleOrganizationsCell";
import { SupportRoleCell } from "components/modules/tableCells/SupportRoleCell";
import { UserStatusCell } from "components/modules/tableCells/UserStatusCell";
import { Users } from "components/organisms/Users";
import { ResultsPerPage } from "gql/graphql";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import {
  usePrefetchWithSnack,
  useQueryWithSnack,
} from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { getFormattedDate } from "utils/utilFunctions";

export const SUPPORT_USER_LIST_QUERY = gql`
  query UsersForSupport($pagination: Pagination) {
    identities(pagination: $pagination) {
      id
      email
      firstName
      lastName
      inviteState
      lastLogin
      affiliations {
        organization {
          id
          name
          country
        }
      }
      supportRole {
        title
      }
      ...UserCardContent
    }
  }
  ${UserCardContent.fragments.identity}
`;

export const USER_COUNT_QUERY = gql`
  query UsersCount {
    identityCount
  }
`;

const columns: GridColDef[] = [
  {
    field: "user",
    flex: 1,
    maxWidth: 300,
    minWidth: 180,
    headerName: "User",
    valueGetter: (params) => useUserDisplayName(params.row),
    renderCell: (params) => (
      <UserCardContent identity={params.row} size="small" hideIcon />
    ),
  },
  {
    field: "organization",
    flex: 1,
    maxWidth: 300,
    minWidth: 180,
    headerName: "Organization",
    sortable: false,
    renderCell: (params) => (
      <MultipleOrganizationCell hideAvatar identity={params.row} />
    ),
  },
  {
    field: "support",
    width: 110,
    headerName: "Support",
    valueGetter: (params) => params.row.supportRole,
    renderCell: SupportRoleCell,
  },
  {
    field: "lastLogin",
    width: 150,
    type: "Date",
    align: "right",
    headerAlign: "right",
    headerName: "Last login",
    renderCell: (params) => getFormattedDate(params.row?.lastLogin),
  },
  {
    field: "status",
    width: 150,
    headerName: "Status",
    valueGetter: (params) => params.row.inviteState,
    renderCell: UserStatusCell,
  },
];

export const SupportUserListPage = () => {
  const [page, setPage] = useState(0);
  const { data: countData } = useQueryWithSnack(USER_COUNT_QUERY);
  const prefetch = usePrefetchWithSnack(SUPPORT_USER_LIST_QUERY);

  const pageSize = 25;
  const pagesToPrefetch = 5;

  const { data, loading } = useQueryWithSnack(SUPPORT_USER_LIST_QUERY, {
    variables: {
      pagination: {
        resultsPerPage: ResultsPerPage.ResultsPerPage25,
        page: page,
      },
    },
  });

  useEffect(() => {
    const debouncedPrefetch = debounce(() => {
      // Prefetch pages close to current
      for (let i = Math.max(page - 5, 0); i <= page + pagesToPrefetch; i++) {
        prefetch({
          pagination: {
            resultsPerPage: ResultsPerPage.ResultsPerPage25,
            page: i,
          },
        });
      }

      // Prefetch last page
      prefetch({
        pagination: {
          resultsPerPage: ResultsPerPage.ResultsPerPage25,
          page: Math.max(
            Math.ceil((countData?.identityCount ?? 0) / pageSize) - 1,
            0,
          ),
        },
      });
    }, 200);

    debouncedPrefetch();

    return () => {
      debouncedPrefetch.cancel();
    };
  }, [page, prefetch, countData?.identityCount]);

  return (
    <Grid container spacing={6}>
      <HeaderGrid crumbs={[{ label: "Support" }, { label: "Users" }]} />
      <Grid item xs={12}>
        <Users
          users={data?.identities || []}
          columns={columns}
          loading={loading}
          page={page}
          onPageChanged={(pageNumber) => {
            setPage(pageNumber);
          }}
          pageSize={pageSize}
          rowCount={countData?.identityCount ?? 0}
          paginationMode="server"
        />
      </Grid>
    </Grid>
  );
};
