import { Autocomplete, Box, createFilterOptions } from "@mui/material";
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { useUserDisplayName } from "utils/useUserDisplayName";
import { graphql } from "../../../gql";
import { CloudTextField } from "../CloudTextField";
import { CustomPaper } from "./CloudAutocomplete";

//LSBRI - TODO: This is not optimized for extreme cases, should it be?
//              (1000 users = 176 kB)
export const USER_AUTOCOMPLETE_QUERY = graphql(`
  query UserAutoComplete {
    me {
      id
    }
    identities {
      id
      firstName
      lastName
      email
      supportRole {
        title
      }
    }
  }
`);

export const UserAutoComplete = (props: any) => {
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const { data } = useQueryWithSnack(USER_AUTOCOMPLETE_QUERY);

  useEffect(() => {
    props.onChange(
      undefined, // placeholder for the event
      selectedUsers.map((item: any) => item.email || item.inputValue),
    );
  }, [selectedUsers]);

  useEffect(() => {
    if (
      data?.identities.length === 1 &&
      data.identities[0].id !== data.me.id &&
      !props.disableAutoSelect
    ) {
      setSelectedUsers([data.identities[0]]);
    }
  }, [data]);

  const supportUserIds = data?.identities
    .filter((item) => !!item.supportRole)
    .map((item) => item.id);

  return (
    <Autocomplete
      multiple
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoComplete={false}
      sx={{
        height: "48px",
        ...props.sx,
      }}
      value={selectedUsers}
      onChange={(event, newValue: any) => {
        setSelectedUsers(newValue);
      }}
      getOptionDisabled={
        props.disableSupportUsers
          ? (option) => supportUserIds?.includes(option.id) || false
          : undefined
      }
      filterOptions={(options: any, params: any) => {
        const filter = createFilterOptions<any>({
          stringify: (option: any) =>
            option.firstName + option.lastName + option.email,
        });

        const filtered = filter(options, params);
        const { inputValue } = params;

        // Extra option to add custom value
        const isExisting = options.some(
          (option: any) => inputValue === option.title,
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      options={data?.identities || []}
      getOptionLabel={(option: any) => {
        if (option.inputValue) {
          return option.inputValue;
        }

        const displayName = useUserDisplayName(option);
        return displayName;
      }}
      renderOption={(props: any, option: any) => {
        if (option.inputValue) {
          return <li {...props}>{option.title}</li>;
        }
        const displayName = useUserDisplayName(option);

        return (
          <li {...props} key={option.id}>
            {displayName}
            <span
              style={{
                paddingLeft: 4,
                fontSize: 10,
                color: "grey",
              }}
            >
              {option.email}
            </span>
          </li>
        );
      }}
      PaperComponent={CustomPaper}
      renderInput={(params: any) => (
        <CloudTextField
          {...params}
          {...props.textfieldprops}
          autoComplete="off"
          fullWidth
          placeholder={
            selectedUsers.length === 0
              ? "Select existing users or add email of new users"
              : ""
          }
          onBlur={props.onBlur}
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            disableUnderline: true,
            startAdornment: props.hideSearchIcon ? undefined : (
              <Box
                sx={(theme) => ({
                  "& svg:first-child": {
                    color: theme.palette.grey[500],
                  },
                })}
              >
                <IoSearch
                  size={20}
                  style={{
                    marginRight: 8,
                  }}
                />
                {params.InputProps.startAdornment}
              </Box>
            ),
          }}
        />
      )}
    />
  );
};
