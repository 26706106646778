import { gql } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Input,
  Popover,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { CustomScrollBox } from "components/atoms/CustomScrollBox";
import { useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { IoSearchOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useHasPrivilege } from "utils/privileges/usePrivileges";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import {
  Identity,
  Organization,
  Privilege,
  UvdRobot,
} from "../../../gql/graphql";
import { UserCardContent } from "../cards/UserCardContent";
import { FilterSelector } from "../FilterSelector";
import { AllResults } from "./AllResults";
import { OrganizationResults } from "./OrganizationResults";

import { ResultTab } from "./ResultTab";
import { RobotResults } from "./RobotResults";
import { UserResults } from "./UserResults";

export const ResultWrapper = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  ":hover": {
    color: theme.palette.primary.main,
  },
}));

export const GLOBALSEARCH = gql`
  query GlobalSearch($searchText: String!, $limit: Int!) {
    globalSearch(searchText: $searchText, limit: $limit) {
      ... on UvdRobot {
        id
        serialNumber
        robotType
        active {
          organization {
            id
            name
          }
        }
      }
      ... on Organization {
        id
        name
        robotAffiliations {
          active {
            id
          }
          robot {
            serialNumber
          }
        }
        affiliations {
          identity {
            id
          }
        }
      }
      ... on Identity {
        id
        affiliations {
          organization {
            id
          }
        }
        ...UserCardContent
      }
    }
  }
  ${UserCardContent.fragments.identity}
`;

export const NoResults = ({ selectedFilter }: { selectedFilter?: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <Typography color={(theme) => theme.palette.grey[500]}>
        {selectedFilter !== "all"
          ? `No ${selectedFilter} found`
          : "We couldn't find anything matching your search."}
      </Typography>
    </Box>
  );
};

const MAX_ALL_RESULTS = 5;
const MAX_RESULTS_ROBOTS = 6;
const MAX_RESULTS_ORG_USERS = 20;

export type GlobalSearchTabs = "robots" | "users" | "organizations" | "all";

export const GlobalSearch = ({ isSupporter }: { isSupporter: boolean }) => {
  const intl = useIntl();

  const popoverRef = useRef();
  const searchFieldRef = useRef<any>(null);

  const responsiveHeight = window.innerHeight / 2;

  const [searchText, setSearchText] = useState("");
  const [resultPopoverOpen, setResultPopoverOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<any>(null);

  const hasGlobalSearch = useHasPrivilege(
    Privilege.GenericGlobalSearch,
  ).hasPrivilege;

  const [selectedFilter, setSelectedFilter] = useState<GlobalSearchTabs>("all");

  useHotkeys(
    "ctrl+space",
    () => {
      searchFieldRef.current.focus();
    },
    [searchFieldRef],
  );

  const { data: searchData, loading } = useQueryWithSnack(GLOBALSEARCH, {
    variables: {
      searchText: searchText.trim(),
      limit: Math.max(
        // This will fetch more results than needed, but in a controlled way with limited request size
        MAX_ALL_RESULTS,
        MAX_RESULTS_ROBOTS,
        MAX_RESULTS_ORG_USERS,
      ),
    },
    skip: !searchFieldRef.current,
  });

  const handleClose = () => {
    setResultPopoverOpen(false);
    setSearchText("");
    setSelectedFilter("all");
  };

  const handleSelectFilter = (newFilter: string) => {
    setSelectedFilter(newFilter as GlobalSearchTabs);
  };

  const allAssignedRobots =
    searchData?.globalSearch.filter(
      (item: any) => item.__typename === "UvdRobot" && !!item.active,
    ) || [];

  const allUnassignedRobots =
    searchData?.globalSearch.filter(
      (item: any) => item.__typename === "UvdRobot" && !item.active,
    ) || [];

  const allUsers =
    searchData?.globalSearch?.filter(
      (item: any) => item.__typename === "Identity",
    ) || [];

  const allOrgs =
    searchData?.globalSearch?.filter(
      (item: any) => item.__typename === "Organization",
    ) || [];

  const assignedRobotResults: Partial<UvdRobot>[] | any[] = useMemo(() => {
    if (searchText !== "") {
      return selectedFilter === "all"
        ? allAssignedRobots?.slice(0, MAX_ALL_RESULTS)
        : allAssignedRobots;
    } else {
      return (
        allAssignedRobots?.slice(
          0,
          selectedFilter !== "all" ? MAX_RESULTS_ROBOTS : MAX_ALL_RESULTS,
        ) || []
      );
    }
  }, [searchData, selectedFilter]);

  const unassignedRobotResults: Partial<UvdRobot>[] | any[] = useMemo(() => {
    if (searchText !== "") {
      return selectedFilter === "all"
        ? allUnassignedRobots?.slice(0, MAX_ALL_RESULTS)
        : allUnassignedRobots;
    } else {
      return allUnassignedRobots?.slice(
        0,
        selectedFilter !== "all" ? MAX_RESULTS_ROBOTS : MAX_ALL_RESULTS,
      );
    }
  }, [searchData, selectedFilter]);

  const userResults: Partial<Identity>[] | any[] = useMemo(() => {
    if (searchText !== "") {
      return selectedFilter === "all"
        ? allUsers?.slice(0, MAX_ALL_RESULTS)
        : allUsers;
    } else {
      return allUsers?.slice(
        0,
        selectedFilter !== "all" ? MAX_RESULTS_ORG_USERS : MAX_ALL_RESULTS,
      );
    }
  }, [searchData, selectedFilter]);

  const organizationResults: Partial<Organization>[] | any[] = useMemo(() => {
    if (searchText !== "") {
      return selectedFilter === "all"
        ? allOrgs?.slice(0, MAX_ALL_RESULTS)
        : allOrgs;
    } else {
      return allOrgs?.slice(
        0,
        selectedFilter !== "all" ? MAX_RESULTS_ORG_USERS : MAX_ALL_RESULTS,
      );
    }
  }, [searchData, selectedFilter]);

  const showUnassignedRobotResults =
    isSupporter &&
    (selectedFilter === "all" || selectedFilter === "robots") &&
    unassignedRobotResults?.length > 0;

  const renderTabContent = () => {
    switch (selectedFilter) {
      case "all":
        return (
          <AllResults
            assignedRobotResults={assignedRobotResults}
            unassignedRobotResults={unassignedRobotResults}
            isSupporter={isSupporter}
            selectedFilter={selectedFilter}
            onClose={handleClose}
            userResults={userResults}
            organizationResults={organizationResults}
          />
        );
      case "robots":
        return (
          <RobotResults
            assignedRobotResults={assignedRobotResults}
            unassignedRobotResults={unassignedRobotResults}
            isSupporter={isSupporter}
            selectedFilter={selectedFilter}
            onClose={handleClose}
          />
        );
      case "users":
        return (
          <UserResults
            userResults={userResults}
            onClose={handleClose}
            isSupporter={isSupporter}
          />
        );
      case "organizations":
        return (
          <OrganizationResults
            organizationResults={organizationResults}
            onClose={handleClose}
            isSupporter={isSupporter}
          />
        );
      default:
        return null;
    }
  };

  const resultCount = useMemo(() => {
    switch (selectedFilter) {
      case "robots":
        return {
          all:
            allAssignedRobots.length +
            (showUnassignedRobotResults ? allUnassignedRobots.length : 0),
          visible:
            assignedRobotResults.length +
            (showUnassignedRobotResults ? unassignedRobotResults.length : 0),
        };
      case "users":
        return { all: allUsers.length, visible: userResults.length };
      case "organizations":
        return { all: allOrgs.length, visible: organizationResults.length };
      case "all":
        return {
          all:
            allAssignedRobots.length +
            (showUnassignedRobotResults ? allUnassignedRobots.length : 0) +
            allUsers.length +
            allOrgs.length,
          visible:
            assignedRobotResults.length +
            (showUnassignedRobotResults ? unassignedRobotResults.length : 0) +
            userResults.length +
            organizationResults.length,
        };
      default:
        return { all: 0, visible: 0 };
    }
  }, [
    selectedFilter,
    allAssignedRobots,
    showUnassignedRobotResults,
    allUnassignedRobots,
    assignedRobotResults,
    unassignedRobotResults,
    allUsers,
    userResults,
    allOrgs,
    organizationResults,
  ]);

  if (!hasGlobalSearch) return null;

  return (
    <>
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "12px 8px",
          width: "50%",
          minWidth: 250,
          path: {
            color: theme.palette.grey[500],
          },
        })}
        ref={popoverRef}
      >
        <IoSearchOutline
          style={{ height: 20, width: 20, minWidth: 20, marginRight: "8px" }}
        />
        <Input
          sx={{
            "& input": {
              minWidth: 50,
              width: searchText.trim().length === 0 ? "20%" : "100%",
            },
            background: "white",
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onFocus={() => {
            setPopoverAnchor(popoverRef.current);
            setResultPopoverOpen(true);
          }}
          inputRef={searchFieldRef}
          disableUnderline
          placeholder={intl.formatMessage({
            id: "global-search",
            defaultMessage: "Search:",
          })}
          endAdornment={
            searchText.trim().length === 0 ? (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: theme.palette.grey[600],
                })}
              >
                <Box
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.grey[350]}`,
                    borderRadius: 1,
                    padding: "2px 8px",
                  })}
                >
                  Control
                </Box>
                <Typography margin={1}>+</Typography>
                <Box
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.grey[350]}`,
                    borderRadius: 1,
                    padding: "2px 8px",
                  })}
                >
                  Space
                </Box>
              </Box>
            ) : null
          }
        />
      </Box>
      <Popover
        open={resultPopoverOpen}
        anchorEl={popoverAnchor}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              boxShadow: "0px 0px 22px 0px rgba(24, 28, 50, 0.15)",
              padding: "24px",
            },
          },
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: 250,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <FilterSelector
              selected={selectedFilter}
              onChange={handleSelectFilter}
            />
            <CustomScrollBox
              height={responsiveHeight}
              style={{ marginTop: "8px", marginBottom: 0, paddingBottom: 0 }}
            >
              <ResultTab
                resultCount={resultCount}
                selectedFilter={selectedFilter}
              >
                <Box>{renderTabContent()}</Box>
              </ResultTab>
            </CustomScrollBox>
          </>
        )}
      </Popover>
    </>
  );
};
