import { useLazyQuery } from "@apollo/client";
import { Button } from "@mui/material";
import { format } from "date-fns";
import { graphql } from "gql";
import { Disinfection, DisinfectionFilter, ResultsPerPage } from "gql/graphql";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { IoArrowDown } from "react-icons/io5";
import { useIntl } from "react-intl";
import { filterOptionMsg } from "trans/definedMessages/filterMessages";
import { FilterValue } from "types/types";
import { constructErrorId } from "utils/useQueryWithSnack";
import { getSelectedFilterList } from "utils/UVD/getSelectedFilterList";
import { getUVDSerialNumber } from "utils/UVD/getUVDRobotSerialNumber";
import { getMapPositions, getTaskDuration } from "utils/UVD/UVDUtils";
import { useDDFilter } from "./disinfectionFilter/DisinfectionFilterPanel";

const QUERY = graphql(`
  query CSVDisinfections(
    $filter: DisinfectionFilter!
    $pagination: Pagination
  ) {
    disinfections(filter: $filter, pagination: $pagination) {
      disinfections {
        id
        robotId
        robot {
          id
          robot {
            serialNumber
          }
          active {
            serialNumber
          }
          organization {
            id
            name
          }
        }
        robotId
        department
        end
        interruptions {
          cause
          connectionClosed
          connectionUnstable
          fromUI
          functionButton
          heatDetection
          internalError
          localizationLoss
          lowBattery
          personDetection
          resetButton
          robotStuck
          tabletMovement
          walkDetection
        }
        room
        start
        startedBy
        status
        submittedBy
        type
        uvcLightDuration
        positions {
          _id
          name
          status
        }
      }
    }
  }
`);

const COUNT_QUERY = graphql(`
  query CountCSVDisinfections(
    $filter: DisinfectionFilter!
    $pagination: Pagination
  ) {
    disinfections(filter: $filter, pagination: $pagination) {
      count
    }
  }
`);

interface Props {
  filters: DisinfectionFilter;
  disabled?: boolean;
}

export const CSVDisinfectionDownloader = ({ filters, disabled }: Props) => {
  const intl = useIntl();
  const [csvData, setCsvData] = useState<any>(null);
  const csvInstance = useRef<any>();
  const [DDFilters] = useDDFilter();
  const filterList = getSelectedFilterList(DDFilters);

  const [getDisinfections, { loading }] = useLazyQuery(QUERY);
  const [getCount, {}] = useLazyQuery(COUNT_QUERY);

  useEffect(() => {
    if (csvData && (csvInstance.current as any) && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(null);
      });
    }
  }, [csvData, csvInstance]);

  const csvFileName = useMemo(() => {
    let filtersText = "All";

    const getFilterValues = (value: FilterValue) => {
      if (_.isArray(value)) {
        return value
          .map((item: any) => {
            return _.capitalize(item?.name?.toLowerCase());
          })
          .join(" ");
      } else {
        return value;
      }
    };

    if (!_.isEmpty(filterList)) {
      filtersText = _.sortBy(
        filterList.map((filter: any) => {
          return `${intl.formatMessage(
            filterOptionMsg[filter.label],
          )} ${getFilterValues(filter.value)}`; // TODO: translate labels
        }),
      ).join("+");
    }

    if (filtersText.length > 205) {
      // max filename length should be 256 to make sure all OS can handle them
      // we limit the filter text to 205, to make sure we dont make it too long
      filtersText = filtersText.slice(205, filtersText.length);
    }

    return `UVD-Robots-Disinfections_${format(
      new Date(),
      "yyyyMMdd_hhmmss",
    )}_${filtersText}.csv`;
  }, [filters]);

  const handleDownload = async () => {
    // First get the total count
    const countResult = await getCount({
      variables: {
        filter: filters,
        pagination: {
          resultsPerPage: ResultsPerPage.ResultsPerPage100,
          page: 0,
        },
      },
    });

    const totalCount = countResult.data?.disinfections.count || 0;
    const resultsPerPage = 10000;
    const totalPages = Math.ceil(totalCount / resultsPerPage);
    let allDisinfections: any[] = [];

    // Confirmation check for large downloads
    if (totalCount > 10000) {
      const shouldProceed = window.confirm(
        `You are about to download ${totalCount} records.\nThis may take several minutes. Do you want to proceed?`,
      );
      if (!shouldProceed) {
        return;
      }
    }

    for (let page = 0; page < totalPages; page++) {
      try {
        const result = await getDisinfections({
          variables: {
            filter: filters,
            pagination: {
              resultsPerPage: ResultsPerPage.ResultsPerPage10000,
              page,
            },
          },
        });

        if (result.data?.disinfections.disinfections) {
          allDisinfections = [
            ...allDisinfections,
            ...result.data.disinfections.disinfections,
          ];
        }
      } catch (error) {
        enqueueSnackbar(
          `Could not fetch data. Id: ${constructErrorId(QUERY)}`,
          {
            variant: "error",
          },
        );
        return;
      }
    }

    const csvData = await getCsvData(allDisinfections, csvFileName);
    setCsvData(csvData);
  };

  return (
    <>
      <Button
        disabled={disabled || loading}
        onClick={handleDownload}
        variant="outlined"
        size="small"
        startIcon={<IoArrowDown size={16} />}
      >
        Download CSV
      </Button>
      {csvData ? (
        <CSVLink
          data={csvData.data}
          headers={csvData.headers}
          filename={csvData.filename}
          ref={csvInstance}
        />
      ) : undefined}
    </>
  );
};

const getCsvData = async (data: any, csvFileName: string) => {
  if (data) {
    const headers = [
      { label: "Robot type", key: "robotType" },
      { label: "Robot name", key: "robotName" },
      { label: "Disinfection type", key: "disinfectionType" },
      { label: "Status", key: "status" },
      { label: "Room", key: "room" },
      { label: "Department", key: "department" },
      { label: "Start time", key: "startTime" },
      { label: "End time", key: "endTime" },
      { label: "Total duration in s", key: "totalDurationInSecond" },
      { label: "UV-C light duration", key: "uvcLightDuration" },
      { label: "Started By", key: "startedBy" },
      { label: "Finished By", key: "submittedBy" },
      {
        label: "Positions disinfected (count)",
        key: "positionsDisinfectedCount",
      },
      {
        label: "Positions remaining (count)",
        key: "positionsRemainingCount",
      },
      {
        label: "Positions failed driving to point (count)",
        key: "positionsFailedDrivingToPoint",
      },
      { label: "Times localisation lost", key: "timesLocalisationLost" },
      {
        label: "Times Aborted by operator",
        key: "timesAbortedByOperator",
      },
      { label: "Times Fn button pressed", key: "timesFnButtonPressed" },
      { label: "Times Low battery level", key: "timesLowBatteryLevel" },
      {
        label: "Times Reset button pressed",
        key: "timesResetButtonPressed",
      },
      {
        label: "Times Body heat detection",
        key: "timesBodyHeatDetection",
      },
      { label: "Times Walk detection", key: "timesWalkDetection" },
      {
        label: "Could not access iPad motion sensors",
        key: "timesTabletPermission",
      },
      { label: "Times Tablet movement", key: "timesTabletMovement" },
      {
        label: "Times App not relaunched in time",
        key: "timesAppNotRelaunchedInTime",
      },
      { label: "Times Connection lost", key: "timesConnectionLost" },
      {
        label: "Times Not enough free space around the robot",
        key: "timesRobotStuck",
      },
      { label: "Times Fatal error", key: "timesFatalError" },
    ];

    const formattedDisinfectionsResult = data.map(
      (record: Partial<Disinfection>) => {
        const positions = record?.positions ? getMapPositions(record) : null;
        const { taskDurationInSeconds } = getTaskDuration(
          record.start,
          record.end,
        );

        const robotName = !!record?.robot
          ? `${record.robot?.robot.serialNumber}-${record.robot?.organization?.id}`
          : getUVDSerialNumber(record.robotId || "");

        return {
          robotType: "UVD Robot",
          robotName,
          disinfectionType: record.type,

          status: record.status,
          room: record.room,
          department: record.department,
          startTime: record.start,
          endTime: record.end,
          totalDurationInSecond:
            record.end && record.start ? taskDurationInSeconds : 0,
          uvcLightDuration: record.uvcLightDuration,
          startedBy: record.startedBy,
          submittedBy: record.submittedBy,
          positionsDisinfectedCount: positions?.completed.length,
          positionsRemainingCount: positions?.remaining.length,
          positionsFailedDrivingToPoint: positions?.failed.length,
          timesLocalisationLost: record?.interruptions?.localizationLoss,
          timesAbortedByOperator: record?.interruptions?.fromUI,
          timesFnButtonPressed: record?.interruptions?.functionButton,
          timesLowBatteryLevel: record?.interruptions?.lowBattery,
          timesResetButtonPressed: record?.interruptions?.resetButton,
          timesBodyHeatDetection: record?.interruptions?.heatDetection,
          timesWalkDetection: record?.interruptions?.walkDetection,
          timesTabletPermission: record?.interruptions?.tabletPermission,
          timesTabletMovement: record?.interruptions?.tabletMovement,
          timesAppNotRelaunchedInTime: record?.interruptions?.connectionClosed,
          timesConnectionLost: record?.interruptions?.connectionUnstable,
          timesRobotStuck: record?.interruptions?.robotStuck,
          timesFatalError: record?.interruptions?.internalError,
        };
      },
    );

    return {
      data: formattedDisinfectionsResult,
      headers,
      filename: csvFileName,
    };
  }
};
