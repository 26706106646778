import { Box, Button } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { OrganizationCell } from "components/modules/tableCells/OrganizationCell";
import { OrganizationStatusCell } from "components/modules/tableCells/OrganizationStatusCell";
import { OrgDisinfectionCountCell } from "components/modules/tableCells/OrgDisinfectionCountCell";
import { OwnerCell } from "components/modules/tableCells/OwnerCell";
import { graphql } from "gql";
import { ResultsPerPage } from "gql/graphql";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  usePrefetchWithSnack,
  useQueryWithSnack,
} from "utils/useQueryWithSnack";
import { CreateOrganization } from "./dialogs/CreateOrganization";

export const ORG_LIST_QUERY = graphql(`
  query Organizations($pagination: Pagination) {
    organizations(pagination: $pagination) {
      id
      name
      robotCount
      country
      activationState
      identityCount
    }
  }
`);

export const ORG_COUNT_QUERY = graphql(`
  query OrganizationsCount {
    organizationCount
  }
`);

export const ORGS_DISINFECTION_COUNTS_QUERY = graphql(`
  query OrgsDisinfectionCounts($orgIds: [String!]!) {
    disinfectionsPerOrganization(orgIds: $orgIds) {
      orgsDisinfectionCounts
    }
  }
`);

const columns: GridColDef[] = [
  {
    field: "name",
    maxWidth: 300,
    minWidth: 150,
    flex: 1,
    headerName: "Name",
    valueGetter: (params) => params.row?.name,
    renderCell: (params) => <OrganizationCell organization={params.row} />,
  },
  {
    field: "owner",
    maxWidth: 300,
    flex: 1,
    headerName: "Owner",
    sortable: false,
    renderCell: OwnerCell,
  },
  {
    field: "robots",
    maxWidth: 80,
    flex: 1,
    headerName: "Robots",
    align: "right",
    headerAlign: "right",
    valueGetter(params) {
      return params.row.robotCount || 0;
    },
  },
  {
    field: "users",
    maxWidth: 80,
    flex: 1,
    headerName: "Users",
    align: "right",
    headerAlign: "right",
    valueGetter(params) {
      return params.row.identityCount || 0;
    },
  },
  {
    field: "disinfections",
    align: "right",
    headerAlign: "right",
    maxWidth: 180,
    flex: 1,
    headerName: "Disinfections",
    sortable: false,
    renderCell: OrgDisinfectionCountCell,
  },
  {
    field: "status",
    maxWidth: 100,
    flex: 1,
    headerName: "Status",
    sortable: false,
    renderCell: OrganizationStatusCell,
  },
];

export const Organizations = () => {
  const navigate = useNavigate();
  const [createOrgDialogOpen, setCreateOrgDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const { data: countData } = useQueryWithSnack(ORG_COUNT_QUERY);
  const prefetchOrgList = usePrefetchWithSnack(ORG_LIST_QUERY);
  const prefetchDisinfectionCounts = usePrefetchWithSnack(
    ORGS_DISINFECTION_COUNTS_QUERY,
  );

  const pageSize = 25;
  const pagesToPrefetch = 5;

  const { data: orgListDataInitial, loading } = useQueryWithSnack(
    ORG_LIST_QUERY,
    {
      variables: {
        pagination: {
          resultsPerPage: ResultsPerPage.ResultsPerPage25,
          page: page,
        },
      },
    },
  );

  const { data: orgsDisinfectionCountsData } = useQueryWithSnack(
    ORGS_DISINFECTION_COUNTS_QUERY,
    {
      variables: {
        orgIds:
          orgListDataInitial?.organizations.map((item: any) => item.id) || [],
      },
      skip: !orgListDataInitial?.organizations?.length, // No need to fetch if no orgs
    },
  );

  const orgListData = {
    organizations:
      orgListDataInitial?.organizations?.map((org: any) => ({
        ...org,
        disinfectionCount:
          orgsDisinfectionCountsData?.disinfectionsPerOrganization
            ?.orgsDisinfectionCounts?.[org.id] || 0,
      })) || [],
  };

  useEffect(() => {
    const debouncedPrefetch = debounce(async () => {
      // Prefetch pages close to current
      for (let i = Math.max(page - 5, 0); i <= page + pagesToPrefetch; i++) {
        const result = await prefetchOrgList({
          pagination: {
            resultsPerPage: ResultsPerPage.ResultsPerPage25,
            page: i,
          },
        });

        // If we got organizations, prefetch their disinfection counts
        if (result && result.data?.organizations?.length) {
          prefetchDisinfectionCounts({
            orgIds: result.data.organizations.map((org: any) => org.id),
          });
        }
      }

      // Prefetch last page
      const lastPageResult = await prefetchOrgList({
        pagination: {
          resultsPerPage: ResultsPerPage.ResultsPerPage25,
          page: Math.max(
            Math.ceil((countData?.organizationCount ?? 0) / pageSize) - 1,
            0,
          ),
        },
      });

      // Prefetch disinfection counts for last page organizations
      if (lastPageResult && lastPageResult.data?.organizations?.length) {
        prefetchDisinfectionCounts({
          orgIds: lastPageResult.data.organizations.map((org: any) => org.id),
        });
      }
    }, 200); // Wait 200ms between calls

    debouncedPrefetch();

    return () => {
      debouncedPrefetch.cancel();
    };
  }, [page, prefetchOrgList, countData?.organizationCount]);

  const handleNavigate = (params: GridRowParams) => {
    navigate(`/cloud/support/organizations/${params.row.id}`);
  };

  return (
    <>
      <WidgetPaper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <WidgetHeader
            label={
              <FormattedMessage
                id="organizations"
                defaultMessage="Organizations"
              />
            }
          />

          <Button
            onClick={() => setCreateOrgDialogOpen(true)}
            variant="outlined"
            size="small"
          >
            New organization
          </Button>
        </Box>
        <CloudDataGrid
          onRowClick={handleNavigate}
          rows={orgListData?.organizations || []}
          columns={columns}
          loading={loading}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          page={page}
          onPageChanged={(pageNumber) => {
            setPage(pageNumber);
          }}
          pageSize={pageSize}
          rowCount={countData?.organizationCount ?? 0}
          paginationMode="server"
        />
      </WidgetPaper>
      <CreateOrganization
        open={createOrgDialogOpen}
        onClose={() => setCreateOrgDialogOpen(false)}
      />
    </>
  );
};
