import { Organization } from "gql/graphql";
import { useEffect } from "react";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { graphql } from "../../../gql";
import { CloudAutoComplete } from "./CloudAutocomplete";

//LSBRI - TODO: Not optimized for extreme cases, should it be?
//              (1000 orgs = 114 kB)
const ORGANIZATIONS = graphql(`
  query OrganizationsAutoComplete {
    organizations {
      id
      name
      country
    }
  }
`);

export const OrganizationAutoComplete = (props: any) => {
  const { data } = useQueryWithSnack(ORGANIZATIONS);

  useEffect(() => {
    if (data?.organizations.length === 1) {
      props.onChange("", data.organizations[0]);
    }
  }, [data]);

  return (
    <CloudAutoComplete
      options={props?.options || data?.organizations || []}
      getOptionLabel={(option: Organization) => option.name}
      {...props}
    />
  );
};
