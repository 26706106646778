import { useSubscription } from "@apollo/client";
import { Box, Grid, Pagination, useTheme } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { CloudTextField } from "components/atoms/CloudTextField";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { CloudDataGrid } from "components/modules/CloudDataGrid";
import { RobotListCard } from "components/modules/RobotListCard";
import { BatteryCell } from "components/modules/tableCells/BatteryCell";
import { OrganizationCell } from "components/modules/tableCells/OrganizationCell";
import { RobotCell } from "components/modules/tableCells/RobotCell";
import { TechnicalServiceNeededCell } from "components/modules/tableCells/TechnicalServiceNeededCell";
import { UVDRobotStatusCell } from "components/modules/tableCells/UVDRobotStatusCell";
import { ForbiddenPage } from "components/pages/ForbiddenPage";
import { graphql } from "gql";
import { IRobot, ResultsPerPage, UvdRobot } from "gql/graphql";
import _, { debounce } from "lodash";
import { ReactElement, useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getIsForbidden } from "utils/privileges/privilegeUtils";
import {
  usePrefetchWithSnack,
  useQueryWithSnack,
} from "utils/useQueryWithSnack";
import { ON_ROBOT_CHANGED } from "./UnassignedRobots";

export const ASSIGNED_ROBOTS_QUERY = graphql(`
  query AssignedRobots(
    $organizationId: String
    $pagination: Pagination
    $search: String
  ) {
    robots(
      isActive: true
      organizationId: $organizationId
      pagination: $pagination
      search: $search
    ) {
      robots {
        id
        serialNumber
        robotType
        active {
          organization {
            id
            name
            country
          }
        }
        ... on UvdRobot {
          status {
            batteryMergerPercentage
            lastTimeActive
            job {
              state
              type
            }
          }
          version {
            seto
          }
        }
      }
    }
  }
`);

export const ASSIGNED_ROBOTS_COUNT_QUERY = graphql(`
  query AssignedRobotsCount($organizationId: String) {
    robotCount(isActive: true, organizationId: $organizationId)
  }
`);

const columns: GridColDef[] = [
  {
    field: "robot",
    maxWidth: 250,
    minWidth: 150,
    flex: 1,
    headerName: "Robot",
    valueGetter: (params) => params.row?.serialNumber,
    renderCell: (params: GridRenderCellParams) => (
      <RobotCell robot={params.row} showStatusDot disableHoverOnRobotName />
    ),
  },
  {
    field: "organization",
    maxWidth: 250,
    minWidth: 150,
    flex: 1,
    headerName: "Organization",
    valueGetter: (params) => params.row.active?.organization?.name,
    renderCell: (params: GridRenderCellParams) => (
      <OrganizationCell
        organization={params.row.active?.organization}
        hideAvatar
      />
    ),
  },
  {
    field: "battery",
    flex: 1,
    maxWidth: 200,
    headerName: "Battery",
    valueGetter: (params) => params.row?.status?.batteryMergerPercentage,
    renderCell: (params) => (
      <BatteryCell
        robot={params.row}
        hideBattery={false}
        batteryMergerPercentage={params.row?.status?.batteryMergerPercentage}
      />
    ),
  },
  {
    field: "status",
    flex: 1,
    maxWidth: 250,
    headerName: "Status",
    sortable: false,
    renderCell: (params) => <UVDRobotStatusCell robot={params.row} />,
  },
  {
    field: "technical-service",
    headerName: "Technical Service",
    renderCell: TechnicalServiceNeededCell,
    maxWidth: 150,
    flex: 1,
  },
  {
    field: "sw-version",
    maxWidth: 100,
    flex: 1,
    headerName: "SW Version",
    align: "right",
    headerAlign: "right",
    valueGetter(params) {
      return params.row.version?.seto || "";
    },
  },
];

export const AssignedRobots = ({
  columns: columnsProps,
  label,
  showGrid = false,
}: {
  columns?: GridColDef[];
  label?: string | ReactElement;
  showGrid?: boolean;
}) => {
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const theme = useTheme();

  const pageSize = 25;
  const pagesToPrefetch = 5;

  const { data: countData } = useQueryWithSnack(ASSIGNED_ROBOTS_COUNT_QUERY, {
    variables: {
      organizationId: orgId,
    },
  });

  const prefetch = usePrefetchWithSnack(ASSIGNED_ROBOTS_QUERY);

  const { data, loading, error } = useQueryWithSnack(ASSIGNED_ROBOTS_QUERY, {
    variables: {
      organizationId: orgId,
      search,
      pagination: {
        resultsPerPage: ResultsPerPage.ResultsPerPage25,
        page: page,
      },
    },
  });

  const robots = data?.robots.robots as IRobot[] | undefined;

  useEffect(() => {
    const debouncedPrefetch = debounce(() => {
      // Prefetch pages close to current
      for (let i = Math.max(page - 5, 0); i <= page + pagesToPrefetch; i++) {
        prefetch({
          organizationId: orgId,
          search,
          pagination: {
            resultsPerPage: ResultsPerPage.ResultsPerPage25,
            page: i,
          },
        });
      }

      // Prefetch last page
      prefetch({
        organizationId: orgId,
        search,
        pagination: {
          resultsPerPage: ResultsPerPage.ResultsPerPage25,
          page: Math.max(
            Math.ceil((countData?.robotCount ?? 0) / pageSize) - 1,
            0,
          ),
        },
      });
    }, 200);

    debouncedPrefetch();

    return () => {
      debouncedPrefetch.cancel();
    };
  }, [page, prefetch, countData?.robotCount, orgId, search]);

  const sub = useSubscription(ON_ROBOT_CHANGED);
  useEffect(() => {
    if (robots && sub.data && sub.data.robotChanged) {
      const subRobot = sub.data.robotChanged as UvdRobot;
      const foundRobot = robots.find((o) => o.id == subRobot.id);
      if (foundRobot) {
        _.merge(foundRobot, subRobot);
      }
    }
  }, [robots, sub.data]);

  if (getIsForbidden(error)) return <ForbiddenPage />;

  return (
    <WidgetPaper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <WidgetHeader
          label={
            label ?? <FormattedMessage id="robots" defaultMessage="Robots" />
          }
        />
        <CloudTextField
          sx={{ mt: 2, width: "50%" }}
          placeholder="Type serial number"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <>
                <IoSearch size={20} color={theme.palette.grey[500]} />
              </>
            ),
          }}
        />
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          Loading...
        </Box>
      ) : showGrid ? (
        <>
          <Grid container spacing={6} sx={{ mt: 2, mb: 2 }}>
            {(robots ?? []).map((robot) => (
              <Grid item key={robot.id}>
                <RobotListCard
                  robot={robot}
                  onClick={() => navigate(robot.id)}
                />
              </Grid>
            ))}
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <Pagination
              count={Math.ceil((countData?.robotCount ?? 0) / pageSize)}
              page={page + 1}
              onChange={(_, newPage) => setPage(newPage - 1)}
              color="primary"
            />
          </Box>
        </>
      ) : (
        <CloudDataGrid
          onRowClick={(params: GridRowParams) => navigate(params.row.id)}
          rows={robots ?? []}
          columns={columnsProps ?? columns}
          loading={loading}
          pageSize={pageSize}
          page={page}
          onPageChanged={(pageNumber) => {
            setPage(pageNumber);
          }}
          rowCount={countData?.robotCount ?? 0}
          paginationMode="server"
          initialState={{
            sorting: {
              sortModel: [{ field: "robot", sort: "asc" }],
            },
          }}
        />
      )}
    </WidgetPaper>
  );
};
