import { Box, Grid, styled } from "@mui/material";
import { CloudLabel } from "components/atoms/CloudLabel";
import { ListElement, OrderedList } from "components/atoms/ListElements";
import { WidgetHeader } from "components/atoms/WidgetHeader";
import { WidgetPaper } from "components/atoms/WidgetPaper";
import { getEnvironment } from "utils/UVD/environmentUtils";

const StyledLabel = styled(CloudLabel)(({ theme }) => ({
  color: theme.palette.grey[800],
}));

const StyledListElement = styled(ListElement)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

export const MigrationGuideWidget = () => {
  const { oldSystemLink } = getEnvironment();

  return (
    <WidgetPaper>
      <WidgetHeader label="How to migrate" wrapperStyle={{ marginBottom: 4 }} />
      <Grid container item spacing={4}>
        <Grid item xs={4}>
          <Box
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.grey[300]}`,
            })}
          >
            <StyledLabel>Preparing for migration</StyledLabel>
            <OrderedList>
              <StyledListElement>
                Make sure the organisation's robots are on the right
                environment.
              </StyledListElement>
            </OrderedList>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.grey[300]}`,
            })}
          >
            <StyledLabel>What happens during migration?</StyledLabel>
            <OrderedList>
              <StyledListElement>
                We re-create the organisation with the same name and ID, so
                robots stay synced and all disinfection data is preserved.
              </StyledListElement>
              <StyledListElement>
                We re-create the robots in this system and link them to the new
                organization, so no additional robot service is needed.
              </StyledListElement>
              <StyledListElement>
                We re-create the users in this system. Users are automatically
                invited and linked to the new organization. Former Admins get
                the Owner role, others the Viewer role.
              </StyledListElement>
              <StyledListElement>
                Robot/User groups are not needed in this system and are ignored.
              </StyledListElement>
            </OrderedList>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.grey[300]}`,
            })}
          >
            <StyledLabel>After migration</StyledLabel>
            <StyledListElement>
              Any changes in FM 1.0 are not reflected in this system.
            </StyledListElement>
            <StyledListElement>
              Assign Owner, Editor and Viewer roles to customer users based on
              organization needs.
            </StyledListElement>
            <StyledListElement>
              Make sure to remove all UVD users and Support-related users from
              the organization here in FM2. Our staff does not need to be
              enrolled as users in customer orgs, as any support-work can be
              performed with the support role available in FM2.
            </StyledListElement>
            <StyledListElement>
              Make sure to remove all customer users from the organization in{" "}
              {oldSystemLink}. This ensures that customers can no longer log in
              to the old system.
            </StyledListElement>
          </Box>
        </Grid>
      </Grid>
    </WidgetPaper>
  );
};
