import {
  Box,
  Button,
  Dialog,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DrawerHeader } from "components/atoms/drawer/DrawerHeader";
import { Disinfection } from "gql/graphql";
import { useMemo } from "react";
import { IoArrowDown } from "react-icons/io5";
import { IntlProvider } from "react-intl";
import {
  getInterruptionArr,
  getMapPositions,
  getMissingMapMessage,
  getTaskDuration,
  getUVCLightDurationInHMS,
} from "utils/UVD/UVDUtils";
import { downloadPdf } from "../UVDDisinfectionPDF/PDFreport";
import { ExposureMap } from "./ExposureMap";
import { ReportBasicInfo } from "./ReportBasicInfo";
import { ReportStatusInfo } from "./ReportStatusInfo";
import { ReportTabs } from "./ReportTabs/ReportTabs";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedReport: Partial<Disinfection> | undefined;
}

export const DisinfectionReportDialog = ({
  open,
  onClose,
  selectedReport,
}: Props) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const { activeInterruptions } = getInterruptionArr(
    selectedReport?.interruptions,
  );

  const missingMapMessage = getMissingMapMessage(
    selectedReport?.navigationMap?.navigationMapFile?.name,
    selectedReport?.heatMap,
    selectedReport?.room,
  );

  const reportPDFData = useMemo(() => {
    return {
      ...selectedReport,
      interruptions: activeInterruptions.sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      }),
      positions: selectedReport?.positions
        ? getMapPositions(selectedReport)
        : null,
      uvcLightDurationInHMS: getUVCLightDurationInHMS(
        selectedReport?.uvcLightDuration,
      ),
      taskDuration: getTaskDuration(selectedReport?.start, selectedReport?.end)
        .taskDurationInHMS,
      mapError: missingMapMessage,
    };
  }, [selectedReport, missingMapMessage]);

  // If user clicks very shortly after opening the report, the map image will not be available yet
  const onDownload = async () => {
    const tryDownload = (retries = 20, delay = 500) => {
      if ((window as any).mapImageTracker) {
        downloadPdf(reportPDFData, () => console.log("PDF downloaded"));
      } else if (retries > 0) {
        setTimeout(() => tryDownload(retries - 1, delay), delay);
      } else {
        console.warn("Failed to download PDF: mapImageTracker not available");
      }
    };

    tryDownload();
  };

  return (
    <IntlProvider locale="en">
      <Dialog open={open} fullWidth maxWidth={"xxl"} onClose={onClose}>
        <DrawerHeader header="Disinfection Report" onClose={onClose} />
        <Grid
          container
          rowSpacing={4}
          columnSpacing={4}
          padding={6}
          paddingTop={0}
          paddingBottom={0}
        >
          <Grid item xs={6} xl={8} display="flex">
            <ReportBasicInfo selectedReport={selectedReport} />
          </Grid>
          <Grid item xs={6} xl={4} display="flex">
            <ReportStatusInfo selectedReport={selectedReport} />
          </Grid>
          <Grid item xs={6} xl={8} display="flex">
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                height: largeScreen ? 500 : 420,
              }}
            >
              <ExposureMap selectedReport={selectedReport} />
            </Box>
          </Grid>
          <Grid item xs={6} xl={4} display="flex" overflow="auto">
            <ReportTabs selectedReport={selectedReport} />
          </Grid>
        </Grid>
        <Box padding={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ height: 52, width: 180 }}
            startIcon={<IoArrowDown size={24} />}
            onClick={() => {
              onDownload();
            }}
          >
            Download PDF
          </Button>
        </Box>
      </Dialog>
    </IntlProvider>
  );
};
